export default{
    data: function () {
        return {
            serviceList:[] 
        }
    },
    created(){
        this.getServiceList();
    },
    methods: {
        getServiceList: function () {
            this.$post('/api/index/serviceList.html', {
                page:1,
                pageSize:200
            }).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.serviceList  = res.data || []      
                }else{
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        }
    },
}