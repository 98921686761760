<template>
  <div class="c-width">
    <div class="service">
      <iTitle title="我们的服务" subTitle=" 梦想&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;诚信&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;专业&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;创新" />
      <div class="cf class-box">
        <classItem
          class="fl"
          v-for="item in serviceList"
          :key="item.id"
          :className="item.title"
          :classInfo="item.brief"
          :classImg="item.url"
          :id="item.id"
        />
      </div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
import iTitle from "@/component/i-title/src/i-title";
import classItem from "@/component/class-item/src/class-item";

export default {
  mixins,
  components: {
    iTitle,
    classItem,
  },
};
</script>
<style scoped>
@import "../service.css";
</style>